:root {
  --primary-color: #00415a;
  --secondary-color: #eee;
  --tertiary-color: #c4d8e2;
  --quaternary-color: #a14a76;
  --quinary-color: #56351e;
  --senary-color: #28965a;
  --septary-color: #6290c3;
  --dark-color: #222;
  --warning-color: #bc0606;
  --warning-text-color: white;
  --link-color: #0cce6b;
  --link-hover-color: var(--text-color);
  --odd-card-bg: #69257e;
  --odd-card-link: #66cddd;
  --even-card-bg: #1d879f;
  --even-card-link: #dad207;
  --background-color: var(--primary-color);
  --text-color: var(--secondary-color);
  --scrollbar-bg: var(--text-color);
  --scrollbar-thumb: var(--septary-color);
  --scrollbar-thumb-hover: var(--senary-color);
  --font: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #052d3d;
    --secondary-color: #ededed;
    --tertiary-color: #aabfc9;
    --quaternary-color: #822453;
    --quinary-color: #502d15;
    --senary-color: #167e45;
    --septary-color: #4674a8;
    --dark-color: #222;
    --warning-color: #990707;
    --warning-text-color: white;
    --link-color: #09ac58;
    --scrollbar-bg: var(--dark-color);
    --odd-card-bg: #5b1e6d;
    --odd-card-link: #66cddd;
    --even-card-bg: #167489;
    --even-card-link: #faf341;
  }
}

* {
  box-sizing: border-box;
}

::selection {
  background-color: var(--text-color);
  color: var(--background-color);
}

a::selection {
  background-color: var(--link-color);
  color: var(--dark-color);
}

a:hover::selection {
  color: var(--link-hover-color);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-bg);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

body, html {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font);
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  flex-direction: column;
  display: flex;
}

main {
  background-image: url("resources/Clements.jpg");
  background-size: cover;
  background-attachment: fixed;
  justify-content: center;
  display: flex;
}

@media (width <= 1000px) {
  main {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    margin: 0;
  }

  .maincontent {
    width: 100%;
    overflow-x: hidden;
  }

  #analytics_ignore {
    margin: 0 !important;
  }

  header {
    flex-wrap: wrap;
    height: 55px;
  }

  header[data-expanded="true"] {
    z-index: 999;
    background-color: var(--primary-color);
    box-shadow: var(--tertiary-color) 5px 5px 15px;
    align-items: start;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  header[data-expanded="true"] > .header_right {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  header[data-expanded="false"] > .header_right {
    display: none;
  }

  header[data-expanded="true"] .header_link {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 15px;
    display: flex;
  }

  #volunteer_request_iframe {
    display: none;
  }

  .modal {
    width: 100%;
    height: 100%;
  }

  .header_left {
    width: 100%;
  }

  .warning_banner[data-show="true"] {
    min-height: 100px;
  }
}

@media (width >= 1000px) {
  .maincontent {
    width: 50%;
  }

  header {
    height: 80px;
  }

  .modal {
    width: 500px;
    height: 500px;
  }

  .menu_icon {
    display: none;
  }

  .warning_banner[data-show="true"] {
    min-height: 80px;
  }

  .maincontent:not(.has_sidebar) {
    width: 50%;
  }

  .maincontent.has_sidebar {
    width: 45%;
  }

  .sidebar {
    width: 20%;
    margin-left: 15px;
  }
}

.maincontent {
  opacity: .92;
  background-color: var(--primary-color);
  text-align: center;
  min-height: 80vh;
  padding: 20px;
  line-height: 25px;
}

.content404 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.center {
  text-align: center;
}

.maincontent p {
  margin-top: 30px;
  margin-bottom: 30px;
}

.warning_banner[data-show="false"] {
  display: none;
}

.warning_banner[data-show="true"] {
  --accent: var(--warning-color);
  --accent-text-color: var(--warning-text-color);
  background-color: var(--accent);
  color: var(--accent-text-color);
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
  display: flex;
  overflow-y: auto;
}

.warning_banner[data-positive="true"] {
  --accent: var(--senary-color);
  --accent-text-color: var(--text-color);
}

.warning_banner[data-show="true"] ::selection {
  color: var(--accent);
  background-color: var(--accent-text-color);
}

.sidebar {
  opacity: .92;
  background-color: var(--primary-color);
  padding: 10px;
  overflow: auto;
}

.sidebar:has(.fingerprint-spinner) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.sidebar > .fingerprint-spinner {
  margin-top: 30px;
}

.sidebar_element {
  border: 2px solid var(--quaternary-color);
  text-align: center;
  border-radius: 5px;
  margin: 12px 8px;
  padding: 5px;
}

.sse_item {
  text-align: center;
  margin-top: 2px;
  margin-bottom: 2px;
}

.structured_sidebar_element:nth-child(odd) {
  background-color: var(--odd-card-bg);
}

.structured_sidebar_element:nth-child(odd) a {
  color: var(--odd-card-link);
}

.structured_sidebar_element:nth-child(odd) a::selection {
  background-color: var(--odd-card-link);
  color: var(--odd-card-bg);
}

.structured_sidebar_element:nth-child(odd) a:hover::selection {
  color: var(--even-card-link);
}

.structured_sidebar_element:nth-child(2n) {
  background-color: var(--even-card-bg);
}

.structured_sidebar_element:nth-child(2n) a {
  color: var(--even-card-link);
}

.structured_sidebar_element:nth-child(2n) a::selection {
  background-color: var(--even-card-link);
  color: var(--even-card-bg);
}

.structured_sidebar_element:nth-child(2n) a:hover::selection {
  color: var(--odd-card-bg);
}

.sse_item.title {
  font-weight: bold;
}

.sse_item.link, .break_all {
  word-break: break-all;
}

.warning_banner[data-show="true"] a {
  color: var(--tertiary-color);
  border-bottom: 2px dashed var(--primary-color);
}

.warning_banner[data-show="true"] a::selection {
  background-color: var(--tertiary-color);
  color: var(--primary-color);
}

.warning_banner[data-show="true"] a:hover::selection {
  color: var(--text-color);
}

.warning_banner_text_wrapper {
  align-items: center;
  display: flex;
}

.warning_banner_text {
  display: block;
}

.align_left {
  text-align: left;
}

.notification {
  margin: 15px;
  padding: 10px;
}

.notification_green {
  background-color: var(--senary-color);
  color: var(--text-color);
}

.notification_green > p::selection, .notification_green > h5::selection {
  color: var(--senary-color);
  background-color: var(--text-color);
}

.notification_blue {
  background-color: var(--septary-color);
  color: var(--text-color);
}

.notification_blue > p::selection, .notification_blue > h5::selection {
  color: var(--septary-color);
  background-color: var(--text-color);
}

.notification_blue a:not(.aexempt) {
  color: var(--even-card-link);
}

.notification_pink {
  background-color: var(--quaternary-color);
  color: var(--text-color);
}

.notification_pink a:not(.aexempt) {
  color: #14ccd6;
}

.notification_pink a:not(.aexempt)::selection {
  color: var(--primary-color);
  background-color: #14ccd6;
}

.notification_pink > p::selection, .notification_pink > h5::selection {
  color: var(--quaternary-color);
  background-color: var(--text-color);
}

.medal_list_button {
  --border-size: 5px;
  --border-color: var(--odd-card-link);
  background-color: var(--senary-color);
  color: var(--light-color);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-right: var(--border-size) solid var(--border-color);
  border-bottom: var(--border-size) solid var(--border-color);
  border-radius: 5px;
  padding: 10px;
  transition: border-width .2s linear;
}

.medal_list_button:hover {
  border-width: 1px;
}

.notification > p {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
}

.notification > h5 {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 20px;
}

.hour_requirement {
  color: var(--secondary-color);
  box-shadow: 5px 5px 10px var(--secondary-color);
  border: 2px solid var(--secondary-color);
  background: linear-gradient(to bottom right, #333, #555, #888);
  border-radius: 40px;
  width: 350px;
  margin: 20px auto;
  padding: 10px;
}

.hour_requirement > * {
  margin: 20px 0 !important;
}

.hour_requirement > h3 {
  color: var(--even-card-link);
  font-size: large;
  font-weight: normal;
}

.hour_requirement #hr_req {
  color: var(--odd-card-link);
  font-family: Arial, Helvetica, sans-serif;
  font-size: xx-large;
  font-weight: bold;
}

.hour_requirement #org_req {
  font-size: normal;
  font-weight: normal;
}

.faq_item {
  background-color: var(--dark-color);
  border-radius: 10px;
  margin: 20px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.faq_item > summary {
  padding: 5px;
  list-style: none;
}

details > summary {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

details summary > * {
  display: inline;
}

.contract_link {
  color: var(--septary-color);
  border-bottom: 2px dashed #0000;
}

.contract_link:hover {
  border-bottom: 2px dashed var(--senary-color);
}

.download_contract_link {
  color: inherit;
  justify-content: center;
  align-items: center;
  transition: color .3s;
  display: flex;
}

.download_contract_link:hover {
  color: var(--senary-color);
}

header {
  justify-content: space-between;
  display: flex;
}

.header_left {
  vertical-align: middle;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.menu_icon {
  vertical-align: middle;
  margin-right: 15px;
}

.header_text {
  flex-direction: column;
  padding: 6px;
  display: flex;
}

.header_title {
  font-size: x-large;
}

.header_description {
  font-size: small;
}

.header_right {
  align-items: center;
  display: flex;
}

.header_link {
  background-color: var(--quaternary-color);
  color: var(--text-color);
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 10px;
  text-decoration: none;
  transition: background-color .25s;
}

.header_link:hover, .header_link::selection {
  color: var(--quaternary-color);
  background-color: var(--text-color);
}

.header_link:hover::selection {
  background-color: var(--quaternary-color);
  color: var(--text-color);
}

a {
  cursor: pointer;
}

footer {
  justify-content: center;
  align-items: center;
  min-height: 50px;
  padding: 10px;
  font-size: small;
  display: flex;
}

.modal {
  background-color: var(--background-color);
  box-shadow: var(--tertiary-color) 5px 5px 15px;
  border-radius: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#modal_about {
  width: 300px;
  height: 300px;
  padding: 20px;
}

.modal p {
  text-align: center;
  padding: 10px;
}

.modal_header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.close_modal {
  cursor: pointer;
  transition: background-color .3s;
}

.close_modal:hover {
  background-color: var(--warning-color);
}

.content404 hr {
  width: 100%;
}

.content404 .downloadable_form, .content404 .about_link, .content404 .url404 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.downloadable_form, .about_link, .url404 {
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--tertiary-color);
  color: var(--dark-color);
  box-shadow: var(--tertiary-color) 0px 2px 10px;
  cursor: pointer;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 10px;
  transition: background-color .25s;
  display: flex;
}

.form_name, .link_name, .url404_name {
  color: inherit;
  text-decoration: none;
}

.downloadable_form:hover, .about_link:hover, .url404:hover {
  background-color: var(--senary-color);
  color: var(--text-color);
}

.form_container {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.download_icon, .link_icon, .url404_icon {
  transition: color .25s;
}

.download_icon:hover, .link_icon:hover, .url404_icon:hover {
  color: var(--quinary-color);
}

.header_half {
  width: 50%;
}

.aexempt {
  text-decoration: none;
}

a:not(.aexempt) {
  color: var(--link-color);
  border-bottom: 2px solid #0000;
  text-decoration: none;
  transition: color .2s;
}

a:not(.aexempt):hover {
  border-bottom: 2px dashed var(--septary-color);
  color: var(--link-hover-color);
}

.large_warning {
  color: var(--warning-color);
  font-size: xx-large;
}

.text_center {
  text-align: center;
}

.width_100 {
  width: 100%;
}

#analytics_ignore {
  background-color: var(--septary-color);
  color: var(--light-color);
  border-radius: 10px;
  margin-right: 10px;
  padding: 5px 10px;
  display: none;
}

@media print {
  p {
    page-break-before: always;
  }

  .hide_on_print {
    display: none;
  }

  .maincontent:not(.hide_on_print) {
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow: visible;
  }

  body, html {
    height: 100%;
    display: inline-block;
  }

  footer {
    display: none;
  }

  #analytics_ignore {
    display: none !important;
  }
}

@media not print {
  .hide_on_not_print {
    display: none;
  }

  .maincontent {
    overflow-y: auto;
  }
}

#medal_list_grid {
  grid-template-columns: repeat(auto-fit, minmax(min-content, 200px));
  justify-content: center;
  display: grid;
}

.medal_list_item {
  --border-size: 5px;
  --border-color: var(--odd-card-link);
  background-color: var(--quaternary-color);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-right: var(--border-size) solid var(--border-color);
  border-bottom: var(--border-size) solid var(--border-color);
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  transition: border-width .2s linear;
}

.medal_list_item:hover {
  border-width: 1px;
}

#medal_list_marquee {
  clear: both;
  overflow: hidden;
}

#medal_list_name {
  color: var(--odd-card-link);
  font-size: large;
  font-weight: bold;
}

#copied_dialog {
  background-color: var(--senary-color);
  color: var(--light-color);
  width: 270px;
}

#error_dialog {
  background-color: var(--quaternary-color);
  color: var(--light-color);
  width: 440px;
}

.popup_dialog {
  opacity: 0;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-family: Lucida Sans;
  font-size: large;
  font-weight: bold;
  transition: opacity .2s;
  display: flex;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
/*# sourceMappingURL=index.c5931f4c.css.map */
